var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-container',[_c('v-row',{staticClass:"mt-4 mb-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"sm":"8"}},[_c('v-card',{staticClass:"pb-0 elevation-0"},[_c('div',{staticClass:"d-flex pa-0 align-center"},[_c('v-spacer'),_c('v-spacer'),_c('span',{on:{"click":function($event){_vm.privateKeyFormDialogAddShow = !_vm.privateKeyFormDialogAddShow}}},[_c('PrivateKeyFormDialogAdd',{attrs:{"show":_vm.privateKeyFormDialogAddShow,"data-test":"privateKeyFormDialogFirst-component"},on:{"update:show":function($event){_vm.privateKeyFormDialogAddShow=$event}}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getListPrivateKeys,"data-test":"dataTable-field","server-items-length":_vm.getNumberPrivateKeys,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToFingerprint(item.data))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:'menu'+_vm.getListPrivateKeys.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list-item',{on:{"click":function($event){_vm.showPrivateKeyFormDialog(_vm.getListPrivateKeys.indexOf(item))}}},[_c('PrivateKeyFormDialogEdit',{attrs:{"key-object":item,"show":_vm.privateKeyFormDialogShow[_vm.getListPrivateKeys.indexOf(item)],"data-test":"privateKeyFormDialogEdit-component"},on:{"update:show":function($event){_vm.$set(_vm.privateKeyFormDialogShow, _vm.getListPrivateKeys.indexOf(item), $event)}}})],1),_c('v-list-item',{on:{"click":function($event){_vm.showPrivateKeyDelete(_vm.getListPrivateKeys.indexOf(item))}}},[_c('PrivateKeyDelete',{attrs:{"fingerprint":item.data,"action":"private","show":_vm.privateKeyDeleteShow[_vm.getListPrivateKeys.indexOf(item)],"data-test":"privateKeyDelete-component"},on:{"update:show":function($event){_vm.$set(_vm.privateKeyDeleteShow, _vm.getListPrivateKeys.indexOf(item), $event)}}})],1)],1)],1)]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary"},[_vm._v(" Privacy Policy ")]),_c('v-card-text',{staticClass:"mt-4"},[_vm._v(" The private key is never submitted to ShellHub, it gets stored in your browser’s local storage, only the public key gets uploaded and stored by ShellHub. ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"px-6"},[_c('v-checkbox',{attrs:{"label":"Never show this again"},model:{value:(_vm.privatekeyPrivacyPolicy),callback:function ($$v) {_vm.privatekeyPrivacyPolicy=$$v},expression:"privatekeyPrivacyPolicy"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","data-test":"gotIt-btn"},on:{"click":_vm.accept}},[_vm._v(" Got it ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }